import { useParams, usePathname } from 'next/navigation';

const usePathnameWithoutLocale = () => {
  const pathname = usePathname();
  const pathSegments = pathname.split('/');
  const { locale } = useParams<{ locale: string }>();

  return pathSegments
    .filter((segment, i) => !(segment === locale && i === 1))
    .join('/');
};
export default usePathnameWithoutLocale;
