import { getImageProps } from 'next/image';
import { useTranslations } from 'next-intl';
import { type SourceHTMLAttributes, useMemo, useRef } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import type { ImageProps } from './types';
import { getSrcUrl } from './utils';

interface LocalizedSourceHTMLAttributes
  extends SourceHTMLAttributes<HTMLSourceElement> {
  src: MessageKey;
}

export interface ArtDirectionImageProps
  extends Omit<ImageProps, 'src' | 'localizedSrc'> {
  readonly localizedSrcSet?: LocalizedSourceHTMLAttributes[];
  readonly srcSet?: SourceHTMLAttributes<HTMLSourceElement>[];
}

const ArtDirectionImage = ({
  alt = '',
  className,
  format = 'jpg',
  localizedAlt,
  localizedSrcSet,
  srcSet = [],
  ...props
}: ArtDirectionImageProps): JSX.Element => {
  const t = useTranslations();
  const imagePropsRef = useRef<ImageProps | object>({});
  const sources = useMemo((): (SourceHTMLAttributes<HTMLSourceElement> & {
    key: string;
  })[] => {
    const _srcSet = localizedSrcSet
      ? localizedSrcSet.map(({ src, ...source }) => ({
          ...source,
          src: t(src),
        }))
      : srcSet;

    return _srcSet.map(({ media, src, ...attrs }, i) => {
      if (!src) {
        return {
          key: i.toString(),
          media,
          srcSet: '',
          ...attrs,
        };
      }

      const {
        props: { srcSet: imagePropsSrcSet, ...rest },
      } = getImageProps({
        alt,
        src: getSrcUrl(src, format),
        ...props,
      });

      imagePropsRef.current = rest;

      return {
        key: src + media,
        media,
        srcSet: imagePropsSrcSet,
        ...attrs,
      };
    });
  }, [srcSet, format, alt, props]);

  return (
    <picture data-cy="art-direction-image">
      {sources.map(({ key, media, srcSet: _srcSet, ...attrs }) => (
        <source
          data-cy="art-direction-image-source"
          key={key}
          media={media}
          srcSet={_srcSet}
          {...attrs}
        />
      ))}
      <img
        {...imagePropsRef.current}
        alt={localizedAlt ? t(localizedAlt) : alt}
        className={className}
        data-cy="art-direction-image-img"
        sizes={undefined}
      />
    </picture>
  );
};

export default ArtDirectionImage;
