import { type AbstractIntlMessages } from 'next-intl';

export const locales = ['en', 'de', 'fi', 'es', 'fr', 'it', 'ja'] as const;

export type Locale = (typeof locales)[number];

export const getMessages = async (locale: string) => {
  return (
    (await import(`../public/locales/${locale}.json`)) as {
      default: AbstractIntlMessages;
    }
  ).default;
};
