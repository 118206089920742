import { BodyLinkV3, type FootnotesProps } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';

import {
  BUSINESS_PATHNAME,
  GEN3_POP_PATHNAME,
  MEMBERSHIP,
  POP_PATHNAME,
  WHY_OURA_PATHNAME,
} from '../consts/pathnames';
import useOuraGen4Enabled from './useOuraGen4Enabled';

const useFootnotesProps = (pathname: string): FootnotesProps => {
  const t = useTranslations();
  const gen4Enabled = useOuraGen4Enabled();

  const GEN3_POP_PROPS: FootnotesProps = {
    footnotes: [
      {
        content: t('pop_footnote_how_accurate_is_oura'),
        marker: '*',
        linkProps: {
          href: t('pop_footnote_how_accurate_is_oura'),
        },
      },
      {
        content: t('pop_footnote_temperature_validated_accurate'),
        marker: '**',
        linkProps: {
          href: t('pop_footnote_temperature_validated_accurate'),
        },
      },
      {
        marker: '***',
        content: t('footnotes_membership_pricing'),
      },
    ],
  };

  if (pathname === POP_PATHNAME) {
    if (gen4Enabled) {
      return {
        className: '!bg-[#5A5958] !pb-16',
        footnotes: [
          {
            content: t.rich('pop_gen4_battery_life_footnote', {
              ringBatteryTipsLink: (chunks: ReactNode) => (
                <BodyLinkV3
                  className="text-current"
                  href="https://support.ouraring.com/hc/en-us/articles/360046218953-Ring-Battery-Tips"
                  target="_blank"
                >
                  {chunks}
                </BodyLinkV3>
              ),
            }) as string,
            marker: '*',
          },
        ],
      };
    } else {
      return GEN3_POP_PROPS;
    }
  }

  if (pathname === GEN3_POP_PATHNAME) {
    return GEN3_POP_PROPS;
  }

  if (pathname === WHY_OURA_PATHNAME) {
    return {
      footnotes: [
        {
          marker: '1',
          content: t('699_oura_members_survey_footnote'),
        },
        {
          marker: '2',
          content: t('pop_footnote_how_accurate_is_oura'),
          linkProps: {
            href: 'https://ouraring.com/blog/how-accurate-is-oura/',
            target: '_blank',
            color: 'inherit',
          },
        },
        {
          marker: '3',
          content: t('pop_footnote_temperature_validated_accurate'),
          linkProps: {
            href: 'https://ouraring.com/blog/temperature-validated-accurate/',
            target: '_blank',
            color: 'inherit',
          },
        },
        {
          marker: '4',
          content: t('why_oura_footnote_sleep_staging_algorithm'),
          linkProps: {
            href: 'https://ouraring.com/blog/new-sleep-staging-algorithm/',
            target: '_blank',
            color: 'inherit',
          },
        },
      ],
      color: 'sandstone-500',
    };
  }
  if (pathname === MEMBERSHIP) {
    return {
      footnotes: [
        {
          marker: '*',
          content: t('footnotes_membership_pricing'),
        },
        {
          marker: '**',
          content: t('footnote_personal_baseline'),
        },
      ],
    };
  }

  if (pathname === BUSINESS_PATHNAME) {
    return {
      footnotes: [
        {
          marker: '1',
          content: t('business_footer_survey_details'),
        },
        {
          marker: '2',
          content: 'https://ouraring.com/blog/how-accurate-is-oura/',
        },
        {
          marker: '3',
          content: 'https://ouraring.com/blog/temperature-validated-accurate/',
        },
        {
          marker: '4',
          content: 'https://ouraring.com/blog/new-sleep-staging-algorithm/',
        },
        {
          marker: '5',
          content: 'https://ouraring.com/blog/new-sleep-staging-algorithm/',
        },
      ],
    };
  }

  return { footnotes: [] };
};

export default useFootnotesProps;
